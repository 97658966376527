(function () {
	var $btn	= $('#mbl__toggler');
	var $menu	= $('.topo__main');

	$btn.click(function(e) {
		e.preventDefault();

		$menu.addClass('topo__main--shown');

		$btn
			.attr('aria-expanded', 'true')
			.addClass('mbl__toggler--active');

		$('.bg__menu')
			.fadeIn(400)
			.click(function () {
				$menu.removeClass('topo__main--shown');

				$btn
					.attr('aria-expanded', 'true')
					.addClass('mbl__toggler--active');

				$('.bg__menu').fadeOut(400);
			})
	})
})();