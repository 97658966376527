(function() {

	// check();

	$('.servicos__owl').on('initialize.owl.carousel', function() {
		check();
	})

	$('.servicos__owl').on('initialized.owl.carousel', function() {
		check();
	})

	$('.servicos__owl').on('translated.owl.carousel', function() {
		check();
	})

	function check() {
		$('.servicos__owl .owl-item').removeClass('first second third');

		$('.servicos__owl').each(function() {
			var items = $(this).find('.owl-item.active');

			items.each(function(index) {
				if(index == 0) {
					$(this).addClass('first');
				}
				else if(index == 1) {
					$(this).addClass('second');
				}

				else if(index == 2) {
					$(this).addClass('third');
				}
			});
		});
	}
})();