$('.carousel[data-indicator]').each(function() {

	var carousel   = $(this);
	var indicator  = carousel.data('indicator');
	var $indicator = $(`${indicator} li`);

	carousel.on('slide.bs.carousel', function(event) {
		var to = event.to;

		$indicator.removeClass('active');

		$(`${indicator} li[data-slide-to="${to}"]`).addClass('active');
	});

});